import { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import { requireStyle } from 'bv';
import TimeOutExpiredView from './time_out_expired_view';
import {
  confirmTimeOutExpiration,
} from '../../api';

const { CSSBundles } = window;

const TimeOutExpiredContainer = ({ history }) => {
  const [modalIsHidden, hideModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);

  useEffect(() => {
    requireStyle(CSSBundles.timeOutExpired).then(() => {
      setLoading(false);
    });
  }, []);

  const handelButtonClick = useCallback(({ extend }) => {
    setRequestLoading(true);
    confirmTimeOutExpiration({ extension_considered: extend }).then(() => {
      if (extend) history.push('/safer_gambling/portal/time_out');
      hideModal(true);
      setRequestLoading(false);
    }).catch(
      () => {
        setError(true);
        setRequestLoading(false);
      },
    );
  });

  if (modalIsHidden || loading) return null;

  return (
    <>
      <TimeOutExpiredView
        handleButtonClick={handelButtonClick}
        loading={requestLoading}
        error={error}
      />
      {error && (
      <Modal danger iconClosable onCloseClick={() => setError(false)}>
        <p>{t('errors_something_wrong')}</p>
      </Modal>
      )}
    </>
  );
};

TimeOutExpiredContainer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(TimeOutExpiredContainer);
