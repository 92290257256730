import { Link, Icon, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const KycBannerView = () => (
  <RenderInBody>
    <div className="kyc-banner__wrapper">
      <Link to="/account/verification_zone">
        <div className="kyc-banner">
          <span>
            <i className="kyc-banner__icon" />
            {t('javascript.kyc.account_requires_attention')}
          </span>
          <Icon id="arrow-right" />
        </div>
      </Link>
    </div>
  </RenderInBody>
);

export default KycBannerView;
