import PropTypes from 'prop-types';
import withLazyLoad from 'bv-lazy-load';
import {
  CustomerConsentContainer,
  KycBannerContainer,
  DepositLimitConfirmation,
  DueSLConfirmationContainer,
  TimeOutExpiredContainer,
  PnLConfirmation,
  NdlChangeDialog,
} from './components';
import { updateDueStakeLimit, updateDueLossLimit } from './api';
import { userDueLimitsType } from './types';

const TimeOutBannerContainer = withLazyLoad()('timeOutBanner');

const UserMessagesView = ({
  showKycBanner,
  showDepositLimitConfirmation,
  showNdlChange,
  customerConsentMessages,
  timeOutStatus,
  hideCustomerConsentModal,
  dueStakeLimits,
  dueLossLimits,
  userPnL,
}) => (
  <>
    {showDepositLimitConfirmation && <DepositLimitConfirmation />}
    {showKycBanner && <KycBannerContainer />}
    {(customerConsentMessages.length > 0 && !hideCustomerConsentModal) && (
      <CustomerConsentContainer messages={customerConsentMessages} />
    )}
    {(timeOutStatus.status === 'TIME_OUT'
      || timeOutStatus.status === 'PENDING_CONFIRMATION') && (
      <TimeOutBannerContainer expirationDate={timeOutStatus.expirationDate} />
    )}
    {timeOutStatus.status === 'PENDING_CONFIRMATION' && <TimeOutExpiredContainer />}
    {dueStakeLimits.length ? (
      <DueSLConfirmationContainer
        limits={dueStakeLimits}
        translationsNamespace="stake_limits"
        updateEndpoint={updateDueStakeLimit}
      />
    ) : null}
    {dueLossLimits.length ? (
      <DueSLConfirmationContainer
        limits={dueLossLimits}
        translationsNamespace="loss_limits"
        updateEndpoint={updateDueLossLimit}
      />
    ) : null}
    {Object.keys(userPnL).length ? <PnLConfirmation userPnL={userPnL} /> : null}
    {showNdlChange && <NdlChangeDialog />}
  </>
);

UserMessagesView.propTypes = {
  showKycBanner: PropTypes.bool.isRequired,
  showDepositLimitConfirmation: PropTypes.bool.isRequired,
  showNdlChange: PropTypes.bool.isRequired,
  customerConsentMessages: PropTypes.arrayOf(PropTypes.number).isRequired,
  timeOutStatus: PropTypes.shape({
    status: PropTypes.string,
    expirationDate: PropTypes.string,
  }).isRequired,
  hideCustomerConsentModal: PropTypes.bool.isRequired,
  dueStakeLimits: userDueLimitsType,
  dueLossLimits: userDueLimitsType,
  userPnL: PropTypes.shape({
    profitAndLoss: PropTypes.number,
    winnings: PropTypes.number,
    losses: PropTypes.number,
    totalStake: PropTypes.number,
  }).isRequired,
};

UserMessagesView.defaultProps = {
  dueStakeLimits: [],
  dueLossLimits: [],
};

export default UserMessagesView;
