import { getJSON, postJSON, putJSON } from 'bv-fetch';

export const fetchTimeOutStatus = () => getJSON('/api/time_out/status');
export const confirmTimeOutExpiration = (params) => postJSON('/api/time_out/confirmation', { time_out: params });

export const updateDueStakeLimit = (id, params) => (
  putJSON(`/api/stake_limits/${id}`, params)
);
export const updateDueLossLimit = (id, params) => (
  putJSON(`/api/loss_limits/${id}`, params)
);

export const confirmPnLNotification = () => putJSON('/api/pnl_notification');

export const acknowledgeNdlChange = () => putJSON('/api/net_deposit_limit/acknowledge_change');
