import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getCookie } from 'bv';
import { useHistory } from 'react-router-dom';
import { useEventBus, useFetch } from 'bv-hooks';
import {
  decrementCashoutBetsNumber,
  incrementCashoutBetsNumber,
  setCashoutBetsNumber,
} from 'floating-navigation-helpers';
import { hasSportsbook, kycLoginStrategies } from 'global-config';
import { setLocation } from 'bv-helpers/location';
import {
  loadCustomerConsentMessages,
  loadKycStrategiesV2,
  loadRestrictions,
  loadDepositLimitInfo,
  setShowKycBanner,
  setShowNdlChange,
} from 'session-action-types';
import { accountId, loggedIn } from 'bv-helpers/session';
import {
  selectShowKycBanner,
  selectShowDepositLimitConfirmation,
  selectShowNdlChange,
  selectCustomerConsentMessages,
} from 'session-selectors';
import { fetchBetsCount } from 'SharedComponents/my_bets/api';
import { BetUpdateType } from 'SharedComponents/my_bets/constants';
import { subscribeToBets } from 'SharedComponents/my_bets/subscribers';
import subscribeToPrompt from 'SharedComponents/prompt';
import { setReportInfo } from 'AccountHistory/ducks/app';
import * as lossLimitsApi from 'LossLimits/api';
import * as stakeLimitsApi from 'StakeLimits/api';
import { fetchTimeOutStatus } from './api';
import UserMessagesView from './user_messages_view';

const { CUSTOMER_CONSENT_SLUGS } = window.VCSTATE;

const UserMessagesContainer = () => {
  const [timeOutStatus, setTimeOutStatus] = useState({ expirationDate: null, status: 'INACTIVE' });
  const [dueStakeLimits, setDueStakeLimits] = useState([]);
  const [dueLossLimits, setDueLossLimits] = useState([]);
  const [userPnL, setUserPnL] = useState({});
  const [hideCustomerConsentModal, setHideCustomerConsentModal] = useState(false);

  const [fetchedTimeOutStatus, timeOutStatusIsLoading] = useFetch(fetchTimeOutStatus);

  const showKycBanner = useSelector(selectShowKycBanner);
  const showDepositLimitConfirmation = useSelector(selectShowDepositLimitConfirmation);
  const showNdlChange = useSelector(selectShowNdlChange);
  const customerConsentMessages = useSelector(selectCustomerConsentMessages);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const loginStrategies = kycLoginStrategies
    ? kycLoginStrategies.split(',').map((str) => parseInt(str, 10))
    : [];

  const updateDueLimits = (api, setState) => {
    api.fetchLimits().then((limits) => {
      const dueLimits = limits.filter((limit) => limit.pending && limit.dueTime === 0);

      setState(dueLimits);
    });
  };

  const checkDueLimits = (api, setState) => {
    api.fetchGeneralConfig().then(({ enabled }) => {
      if (enabled) updateDueLimits(api, setState);
    });
  };

  useEffect(() => {
    setHideCustomerConsentModal(
      Object.values(CUSTOMER_CONSENT_SLUGS).some((slug) => location.pathname.includes(slug)),
    );
  }, [location.pathname]);

  useEffect(() => {
    if (timeOutStatusIsLoading === false) {
      setTimeOutStatus({
        expirationDate: fetchedTimeOutStatus.expirationDate,
        status: fetchedTimeOutStatus.status,
      });
    }
  }, [timeOutStatusIsLoading]);

  useEffect(() => {
    subscribeToPrompt();
    checkDueLimits(stakeLimitsApi, setDueStakeLimits);
    checkDueLimits(lossLimitsApi, setDueLossLimits);
  }, []);

  useEventBus(`/user/${accountId()}/time-out`, (err, { body: message }) => {
    const { status, expirationDate } = message;

    setTimeOutStatus({
      expirationDate,
      status,
    });
  });

  useEventBus(`/user/${accountId()}/stake-limits/dued`, () => {
    updateDueLimits(stakeLimitsApi, setDueStakeLimits);
  });

  useEventBus(`/user/${accountId()}/loss-limits/dued`, () => {
    updateDueLimits(lossLimitsApi, setDueLossLimits);
  });

  useEventBus(`/c/ssndl-pending/account/${accountId()}`, (err, { body }) => {
    if (body.pending) dispatch(loadDepositLimitInfo(body));
  });

  useEventBus(`/c/ssndl-acceptances/account/${accountId()}`, (err, { body }) => {
    if (!body.confirmed) dispatch(setShowNdlChange(true));
  });

  useEventBus(`/file-reports/generation/user/${accountId()}`, (err, { body }) => {
    dispatch(setReportInfo(body));
  });

  useEventBus(`/user/${accountId()}`, (err, { body: message }) => {
    const { restrictions, kycstrategiesV2 } = message;
    const shouldShowKycBanner = kycstrategiesV2.some((card) => card.status !== 'COMPLETED');

    dispatch(setShowKycBanner(shouldShowKycBanner));
    dispatch(loadKycStrategiesV2(kycstrategiesV2));
    dispatch(loadRestrictions(restrictions));
    dispatch(loadCustomerConsentMessages(message.customerConsentMessages));

    if (localStorage.getItem('run_login_kyc_strategies')) {
      const strategyToRun = kycstrategiesV2.find((
        { kycStrategyId: id, kycProviders }) => loginStrategies.includes(id) && kycProviders.length,
      );
      if (strategyToRun) {
        const strategy = strategyToRun.kycProviders[0];

        switch (strategy.integrationType) {
          case 'PERMANENTLINK':
            history.push(`/${strategy.urls.MOBI}`);
            break;
          default:
        }
      }
      localStorage.removeItem('run_login_kyc_strategies');
    }
  });

  useEventBus(`/accountdailypnl/${accountId()}`, (err, { body: message }) => {
    const {
      profitAndLoss, winnings, losses, totalStake,
    } = message;

    setUserPnL({
      profitAndLoss, winnings, losses, totalStake,
    });
  });

  useEffect(() => {
    if (hasSportsbook) {
      fetchBetsCount().then(({ count }) => setCashoutBetsNumber(count));

      subscribeToBets(({ betUpdateType }) => {
        if (betUpdateType === BetUpdateType.NEW) {
          incrementCashoutBetsNumber();
        } else if (betUpdateType === BetUpdateType.SETTLED) {
          decrementCashoutBetsNumber();
        }
      });
    }
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (!loggedIn() && !getCookie('user_self_excluded')) {
        setLocation(`${location.pathname}?show_session_expired_message=true`);
      }
    }, 3000);
  }, []);

  return (
    <UserMessagesView
      showKycBanner={showKycBanner}
      showDepositLimitConfirmation={showDepositLimitConfirmation}
      showNdlChange={showNdlChange}
      customerConsentMessages={customerConsentMessages}
      timeOutStatus={timeOutStatus}
      hideCustomerConsentModal={hideCustomerConsentModal}
      dueStakeLimits={dueStakeLimits}
      dueLossLimits={dueLossLimits}
      userPnL={userPnL}
    />
  );
};

export default UserMessagesContainer;
