import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { handleCta } from 'bv-services';

const { reduxState } = window;

const Prompt = ({ cta, message, publishAction }) => {
  const ctaOnClick = () => {
    reduxState.store.actionDispatchers.modal.removeModal();
    handleCta(cta);
    publishAction('prompt_click');
  };

  return (
    <div>
      <p>{message}</p>
      {cta && (
        <div className="bvs-button-group">
          <Button primary label={cta.name} onClick={ctaOnClick} />
        </div>
      )}
    </div>
  );
};

Prompt.propTypes = {
  cta: PropTypes.instanceOf(Object),
  message: PropTypes.string.isRequired,
  publishAction: PropTypes.func.isRequired,
};

Prompt.defaultProps = {
  cta: null,
};

export default Prompt;
