import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { userDueLimitType } from '../../types';

const DueSLConfirmationView = (props) => {
  const {
    onModalClose,
    onCancel,
    onConfirm,
    dueLimitConfirmed,
    dueLimitCancelled,
    showError,
    translationsNamespace: tNamespace,
    currentLimit,
    isUpdating,
  } = props;

  const formatLimitStatus = () => {
    const {
      amount, period, periodLabel, typeLabel,
    } = currentLimit;

    const periodWithType = `${periodLabel}, ${typeLabel}`;

    if (amount) return `${toCurrency(amount)} ${periodWithType}`;
    if (!period) return periodWithType;

    const removalTitle = t(`${tNamespace}.current_limits_table.td.pending.removal`);
    return `${removalTitle}: ${periodWithType}`;
  };

  const updateRequestWasSent = showError || dueLimitConfirmed || dueLimitCancelled;

  const modalContent = () => {
    if (showError) return <p>{t('errors_something_wrong')}</p>;
    if (dueLimitConfirmed) return <p>{t(`${tNamespace}.due_limit_confirmation.confirmed`)}</p>;
    if (dueLimitCancelled) return <p>{t(`${tNamespace}.due_limit_confirmation.cancelled`)}</p>;

    return (
      <>
        <p>{t(`${tNamespace}.due_limit_confirmation.notice`)}</p>
        <p>{formatLimitStatus()}</p>
        <p>{t(`${tNamespace}.due_limit_confirmation.should_proceed`)}</p>
      </>
    );
  };

  const modalActions = () => {
    if (!updateRequestWasSent) {
      return [{
        id: 'no-btn',
        secondary: true,
        inverse: true,
        label: t(`${tNamespace}.due_limit_confirmation.false`),
        onClick: onCancel,
        disabled: isUpdating,
      }, {
        id: 'yes-btn',
        secondary: true,
        label: t(`${tNamespace}.due_limit_confirmation.true`),
        onClick: onConfirm,
        disabled: isUpdating,
      }];
    }

    return [{
      id: 'close-btn',
      secondary: true,
      label: t('close'),
      onClick: onModalClose,
    }];
  };

  return (
    <Modal
      info
      icon
      onCloseClick={updateRequestWasSent ? onModalClose : null}
      actions={modalActions()}
    >
      {modalContent()}
    </Modal>
  );
};

DueSLConfirmationView.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  dueLimitConfirmed: PropTypes.bool.isRequired,
  dueLimitCancelled: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
  currentLimit: userDueLimitType.isRequired,
};

export default DueSLConfirmationView;
