import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { requireStyle } from 'bv';
import { showKycBanner } from 'global-config';
import { anyRestrictions, composeRestrictions } from 'VerificationZone/selectors/restrictions';
import KycBannerView from './kyc_banner_view';

const KycBannerContainer = () => {
  const { CSSBundles } = window;
  const [loading, setLoading] = useState(true);
  const restrictions = useSelector(composeRestrictions);
  const anyKycRestrictions = useSelector(anyRestrictions);

  const showBanner = () => {
    const canWithdraw = restrictions[0]?.canPerformAction;
    const otherRestrictions = restrictions.slice(1);
    const anyOtherRestrictions = otherRestrictions.some(
      (restriction) => !restriction.canPerformAction);

    return !anyKycRestrictions
    || ((showKycBanner && !canWithdraw) || anyOtherRestrictions);
  };

  useEffect(() => {
    requireStyle(CSSBundles.kyc).then(() => {
      setLoading(false);

      if (showBanner()) {
        return document.body.classList.add('site-kyc-active');
      }
      return document.body.classList.remove('site-kyc-active');
    });

    return () => {
      document.body.classList.remove('site-kyc-active');
    };
  }, [CSSBundles.kyc, restrictions]);

  if (loading) return null;

  if (showBanner()) {
    return <KycBannerView />;
  }
  return null;
};

export default KycBannerContainer;
