import PropTypes from 'prop-types';
import { Button, Modal, Spinner } from 'bv-components';
import { t } from 'bv-i18n';

const TimeOutExpiredView = ({ handleButtonClick, loading }) => (
  <Modal className="time-out-expired__modal is-info" icon={false}>
    <i className="time-out-expired__icon" />
    <span className="bvs-header time-out-expired__title">
      {t('time_out_finished.title')}
    </span>
    {loading ? <Spinner big={false} />
      : (
        <>
          <Button secondary onClick={() => handleButtonClick({ extend: false })}>
            {t('time_out_finished.ok')}
          </Button>
          <div
            className="time-out-expired__extend-button disabled"
            onClick={() => handleButtonClick({ extend: true })}
          >
            <span className="is-arrow-right">{t('time_out_finished.extend')}</span>
          </div>
        </>
      )}
  </Modal>
);

TimeOutExpiredView.propTypes = {
  handleButtonClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TimeOutExpiredView;
