import { registerHandler } from 'event-bus';
import { publish } from 'bv-services/internal-event-bus';
import { accountNumber } from 'bv-helpers/session';

import Prompt from './components/prompt';

const { ModalHelper } = window;
const publishAction = (data) => (message) => publish(message, data);

const handler = (err, { body: data }) => {
  const publishPromptAction = publishAction(data.crmContext);

  ModalHelper.showInfoMessage({
    title: data.subject,
    iconClosable: true,
    children: <Prompt
      cta={data.cta}
      publishAction={publishPromptAction}
      message={data.content}
    />,
    onClose: () => { publishPromptAction('prompt_dismiss'); },
  });
};

export default () => {
  registerHandler(`/user/prompt/${accountNumber()}`, handler);
};
