import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { setLocation } from 'bv-helpers/location';
import { requireScript } from 'bv';
import DueSLConfirmationView from './due_sl_confirmation_view';
import { userDueLimitsType } from '../../types';

const DueSLConfirmationContainer = ({ limits, translationsNamespace, updateEndpoint }) => {
  const [dueLimitConfirmed, setDueLimitConfirmed] = useState(false);
  const [dueLimitCancelled, setDueLimitCancelled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [showError, setShowError] = useState(false);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const [remainingLimits, setRemainingLimits] = useState([]);
  const [currentLimit, setCurrentLimit] = useState({});

  const { TranslationsBundles } = window;
  const { pathname } = useLocation();

  useEffect(() => {
    const translationsBundles = {
      stake_limits: TranslationsBundles.stakeLimits,
      loss_limits: TranslationsBundles.lossLimits,
    };

    requireScript(translationsBundles[translationsNamespace]).then(() => {
      setTranslationsLoaded(true);
      setIsOpened(true);
    });
  }, []);

  useEffect(() => {
    const sortedLimits = limits.sort((l) => l.id);

    setRemainingLimits(sortedLimits);
    setCurrentLimit(sortedLimits[0]);

    if (!isOpened && translationsLoaded) {
      setIsOpened(true);
    }
  }, [limits]);

  const handleConfirm = () => {
    setIsUpdating(true);
    updateEndpoint(currentLimit.id, { confirmed: true }).then((limit) => {
      if (limit?.id) {
        setDueLimitConfirmed(true);
      } else {
        setShowError(true);
      }
      setIsUpdating(false);
    });
  };

  const handleCancel = () => {
    setIsUpdating(true);
    updateEndpoint(currentLimit.id, { confirmed: false }).then((limit) => {
      if (limit?.id) {
        setDueLimitCancelled(true);
      } else {
        setShowError(true);
      }
      setIsUpdating(false);
    });
  };

  if (!isOpened) return null;

  const clearSubmitResult = () => {
    setDueLimitCancelled(false);
    setDueLimitConfirmed(false);
    setShowError(false);
    setIsUpdating(false);
  };

  const onModalClose = () => {
    setIsOpened(false);

    const updatedRemainingLimits = remainingLimits.filter((l) => l.id !== currentLimit.id);

    if (updatedRemainingLimits.length) {
      setRemainingLimits(updatedRemainingLimits);
      setCurrentLimit(updatedRemainingLimits[0]);
      clearSubmitResult();
      setIsOpened(true);
    } else {
      setRemainingLimits([]);
      setCurrentLimit(null);
      clearSubmitResult();

      const urlRegexp = /safer_gambling\/portal\/(stake|loss)_limits/;

      if (pathname.match(urlRegexp) && (dueLimitCancelled || dueLimitConfirmed)) {
        setLocation(pathname);
      }
    }
  };

  return (
    <DueSLConfirmationView
      currentLimit={currentLimit}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onModalClose={onModalClose}
      isUpdating={isUpdating}
      dueLimitCancelled={dueLimitCancelled}
      dueLimitConfirmed={dueLimitConfirmed}
      showError={showError}
      translationsNamespace={translationsNamespace}
    />
  );
};

DueSLConfirmationContainer.propTypes = {
  limits: userDueLimitsType.isRequired,
  translationsNamespace: PropTypes.string.isRequired,
  updateEndpoint: PropTypes.func.isRequired,
};

export default DueSLConfirmationContainer;
