import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { setLocation } from 'bv-helpers/location';
import withLazyLoad from 'bv-lazy-load';

const CustomerConsentContainer = ({ messages }) => {
  const { VCSTATE } = window;
  const LazyCustomerConsentModal = withLazyLoad()('customerConsentModal');

  const handleLogout = () => {
    localStorage.removeItem('stakes');
    localStorage.removeItem('resumeToken');
    setLocation('/logout');
  };

  return (
    <LazyCustomerConsentModal
      msgs={messages}
      urls={VCSTATE.CUSTOMER_CONSENT_URLS}
      slugs={VCSTATE.CUSTOMER_CONSENT_SLUGS}
      onSecondaryClick={handleLogout}
      secondaryButtonText={t('javascript.customer_consent.do_not_accept')}
      modalClassName="modal_wrapper consent_modal_wrapper"
    />
  );
};

CustomerConsentContainer.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default CustomerConsentContainer;
