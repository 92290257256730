import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sanitize } from 'dompurify';
import { show } from '@intercom/messenger-js-sdk';
import { t, locale } from 'bv-i18n';
import { Modal } from 'bv-components';
import { zendesk } from 'bv-services';
import { v as bvVar } from 'bv';
import { useFeature } from 'bv-hooks';
import { setShowNdlChange } from 'session-action-types';
import { showDangerMessage } from 'modal-helper';

import { acknowledgeNdlChange } from '../../api';

const prefix = 'deposit_limit.messages.ndl_change';

const NdlChangeDialog = () => {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const [intercomEnabled] = useFeature('intercom_live_chat');

  const helpPage = bvVar('depositLimitHelpPage')[locale()] || bvVar('depositLimitHelpPage')['en-gb'];

  useEffect(() => {
    const liveChatLauncher = intercomEnabled ? show : zendesk.liveChat;
    const span = document.querySelector('#live-chat');
    span?.addEventListener('click', liveChatLauncher);

    return () => span?.removeEventListener('click', liveChatLauncher);
  }, [intercomEnabled]);

  return (
    <Modal
      success
      className="ndl-change-deposit-limit"
      actions={[{
        label: t('accept'),
        primary: true,
        disabled: !checked,
        onClick: () => {
          acknowledgeNdlChange()
            .catch(() => {
              showDangerMessage({
                message: t('errors_something_wrong'),
                iconClosable: true,
              });
            }).finally(() => {
              dispatch(setShowNdlChange(false));
            });
        },
      }]}
    >
      <>
        <h3 className="bvs-msg-box__title">{t(`${prefix}.title`)}</h3>
        <section>
          {t(`${prefix}.body`, {
            contactUsLink: `<span id="live-chat">${t('contact_us')}</span>`,
            depositLimitLink: `<a href="${helpPage}" target="_blank">${t('javascript.sg.portal.DEPOSIT_LIMITS.title')}</a>`,
          }).split('\n').map((line) => (
            <p
              dangerouslySetInnerHTML={{
                __html: sanitize(line.trim()),
              }}
            />
          ))}
          <p className="consent-form-row">
            <input
              type="checkbox"
              id="acknowledge-ndl"
              name="acknowledge-ndl"
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <label htmlFor="acknowledge-ndl">
              {t(`${prefix}.acknowledge`)}
            </label>
          </p>
        </section>
      </>
    </Modal>
  );
};

NdlChangeDialog.propTypes = {};

export default NdlChangeDialog;
