export const confirmationStatus = {
  PROMPTED: 'PROMPTED',
  SUBMITTING: 'SUBMITTING',
  CONFIRMED: 'CONFIRMED',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
};

export const actionType = {
  CONFIRM: 'CONFIRM',
  CANCEL: 'CANCEL',
};
