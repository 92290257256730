import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'underscore';
import { t } from 'bv-i18n';
import { closeDepositLimitConfirmation } from 'session-action-types';
import { selectPendingDepositLimit } from 'session-selectors';
import { confirmLimit, cancelLimit } from 'DepositLimits/api';
import { loadCurrentLimits } from 'DepositLimits/duck';
import ConfirmationView from './confirmation_view';
import { confirmationStatus, actionType } from './constants';

const ConfirmationContainer = () => {
  const [status, setStatus] = useState(confirmationStatus.PROMPTED);

  const dispatch = useDispatch();
  const { amount, period } = useSelector(selectPendingDepositLimit) || {};

  const resetDepositLimitData = () => {
    dispatch(loadCurrentLimits());
  };

  const handleClose = () => {
    dispatch(closeDepositLimitConfirmation());
  };

  const handleSubmit = (type) => debounce(async () => {
    setStatus(confirmationStatus.SUBMITTING);
    try {
      if (type === actionType.CONFIRM) {
        await confirmLimit();
        setStatus(confirmationStatus.CONFIRMED);
      } else if (type === actionType.CANCEL) {
        await cancelLimit();
        setStatus(confirmationStatus.CANCELLED);
      }
      resetDepositLimitData();
    } catch {
      setStatus(confirmationStatus.FAILED);
    }
  }, 1000, true);

  return (
    <ConfirmationView
      onConfirm={handleSubmit(actionType.CONFIRM)}
      onCancel={handleSubmit(actionType.CANCEL)}
      onModalClose={handleClose}
      status={status}
      amount={amount}
      period={t(`account_form.period.${period}`)}
    />
  );
};

export default ConfirmationContainer;
