import {
  arrayOf, bool, number, shape, string,
} from 'prop-types';

export const userDueLimitType = shape({
  id: number,
  remaining: number,
  amount: number,
  period: string,
  periodLabel: string,
  type: string,
  typeId: number,
  typeLabel: string,
  expirationDate: string,
  active: bool,
  pending: bool,
  dueTime: number,
  activeFromDate: string,
});

export const userDueLimitsType = arrayOf(userDueLimitType);
