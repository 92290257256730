import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';
import { formatAmount } from 'DepositLimits/helpers';
import { confirmationStatus } from './constants';

const ConfirmationView = ({
  onModalClose,
  onCancel,
  onConfirm,
  amount,
  period,
  status,
}) => {
  let modalContent;
  let modalActions = [];

  switch (status) {
    case confirmationStatus.FAILED:
      modalContent = <p>{t('errors_something_wrong')}</p>;
      break;
    case confirmationStatus.CONFIRMED:
      modalContent = <p>{t('deposit_limit.messages.changed')}</p>;
      break;
    case confirmationStatus.CANCELLED:
      modalContent = <p>{t('deposit_limit.messages.cancelled')}</p>;
      break;
    case confirmationStatus.SUBMITTING:
    case confirmationStatus.PROMPTED:
    default:
      modalContent = (
        <>
          <p>{t('deposit_limit.messages.confirmation.msg1')}</p>
          <p><strong>{formatAmount(amount, period)}</strong></p>
          <p>{t('deposit_limit.messages.confirmation.msg3')}</p>
        </>
      );
      modalActions = [{
        id: 'no-btn',
        label: t('deposit_limit.messages.confirmation.cancel'),
        primary: true,
        inverse: true,
        onClick: onCancel,
        disabled: status === confirmationStatus.SUBMITTING,
      }, {
        id: 'yes-btn',
        label: t('deposit_limit.messages.confirmation.confirm'),
        primary: true,
        onClick: onConfirm,
        disabled: status === confirmationStatus.SUBMITTING,
      }];
  }

  const closable = [
    confirmationStatus.CONFIRMED,
    confirmationStatus.CANCELLED,
    confirmationStatus.FAILED,
  ].includes(status);

  return (
    <Modal
      success={status !== confirmationStatus.FAILED}
      danger={status === confirmationStatus.FAILED}
      iconClosable={closable}
      onCloseClick={onModalClose}
      actions={modalActions}
      className="deposit-limit-confirmation"
    >
      <div>
        {status !== confirmationStatus.FAILED && (
          <h3 className="bvs-msg-box__title">
            {t('account_form.deposit_limit')}
          </h3>
        )}
        {modalContent}
      </div>
    </Modal>
  );
};

ConfirmationView.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  amount: PropTypes.number,
  period: PropTypes.string,
  status: PropTypes.oneOf(Object.values(confirmationStatus)).isRequired,
};

ConfirmationView.defaultProps = {
  amount: null,
  period: null,
};

export default ConfirmationView;
