import { useState } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';
import { toCurrency } from 'bv-helpers/number';
import { confirmPnLNotification } from '../../api';

const prefix = 'profit_and_loss_notification.modal';

const PnLConfirmation = ({
  userPnL: {
    profitAndLoss, winnings, losses, totalStake,
  },
}) => {
  const [modalIsHidden, hideModal] = useState(false);

  if (modalIsHidden) return null;

  const handleConfirm = async () => {
    try {
      await confirmPnLNotification();
    } catch (e) {
      //
    }
    hideModal(true);
  };

  return (
    <Modal
      info
      actions={[{
        id: 'continue-btn',
        label: t(`${prefix}.button_title`),
        primary: true,
        inverse: true,
        onClick: handleConfirm,
      }]}
    >
      <div>
        <h3 className="bvs-msg-box__title">{t(`${prefix}.title`)}</h3>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: sanitize(
                t(`${prefix}.body`, {
                  profitAndLoss: toCurrency(profitAndLoss),
                  winnings: toCurrency(winnings),
                  losses: toCurrency(losses),
                  totalStake: toCurrency(totalStake),
                })),
            }}
          />
        </section>
      </div>
    </Modal>
  );
};

PnLConfirmation.propTypes = {
  userPnL: PropTypes.shape({
    profitAndLoss: PropTypes.number,
    winnings: PropTypes.number,
    losses: PropTypes.number,
    totalStake: PropTypes.number,
  }).isRequired,
};

export default PnLConfirmation;
